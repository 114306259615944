import React, { Component } from 'react';
import getPartURL from './TemporaryURL';

class Viewer extends Component {  
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      valid: false,
      src: "",
      iosSrc: "",
    }
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search);

    var partid = query.get("partId");
    var vehicleid = query.get("vehicleId");
    var color = query.get("color");

    getPartURL(partid, vehicleid, this.partURLCallback);
  }

  partURLCallback = (url) => {
    this.setState({
      loading: false,
      valid: url !== null,
      src: url + '.glb',
      iosSrc: url + '.usdz',
    })
  }

  close = () => {    
    window.close()
  }

  render() {
    return (
      <div style={{width: "100%", height: "100%"}}>
        { this.state.loading && <p>Loading...</p> }

        { !this.state.loading && this.state.valid && 
          <model-viewer 
            ar ar-modes="webxr scene-viewer quick-look" 
            shadow-intensity="1" 
            style={{height: "100%", width: "100%"}} 
            src={this.state.src} 
            alt=""
            title=""
            ios-src={this.state.iosSrc}
            auto-rotate camera-controls>
          </model-viewer> }

        { !this.state.loading && !this.state.valid && <div style={{position: "absolute", left: "100px"}}><p>Invalid partId or vehicleId</p></div> }
        <button style={{position: "absolute", left: "20px", top: "20px"}} onClick={this.close}>Close</button>
      </div>      
    );
  }
}


export default Viewer;