import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Viewer from './pages/Viewer';

import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
        <p>SmartParts</p>
        </Route>
        <Route path="/3dviewer" component={Viewer} />
      </Switch>
    </Router>
  );
}


export default App;
