const validParts = [
  "ErrorMessage",
  "B_W10923-07",
  "K_C9218",
  "R_EJL4443M",
  "R_EJL4444M",
  "S_B2583",
  "S_B2983",
  "S_B76730",
  "S_B77717",
  "S_B77732",
  "S_B77794",
  "S_B77795",
  "S_B77807",
  "S_B77857",
  "S_B97695",
  "S_B98695",
  "S_B577122",
  "FFIJL1032-1",
  "FFIJL1033-1",
  "4WP5014-7857347",
  "4WP8018-797350",
  "4WP9218-797350",
  "4WP9514-7857347",
  "BFG55079",
  "BFG66539",
  "EXP75250",
  "ORC5769-001",
  "TOY355960",
  "TOY360310",
  "WAR88990",
  "WAR89611",
  "WEA4513131",
  "WEA4513132"
];

function getURL(partid, vehicleid, callback) {
  var file = "ErrorMessage";

  var requestedId = partid ? partid : vehicleid ? vehicleid : "ErrorMessage";

  requestedId = requestedId.replace('/', '_').replace('%2F', '_');

  if (requestedId && validParts.includes(requestedId)) {
    file = requestedId;
  }

  if (callback) {
    callback(`https://smtpt.s3.us-east-2.amazonaws.com/${file}`);
  }
}

module.exports = getURL;